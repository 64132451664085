import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal,  } from 'antd'
import { convertItem } from '../EntityList'
import util from '../../../../libs/util'
import styles from './Detail.module.css'

interface Props {
  childRef: any,
  locationTypeItems: Array<any>,
  cinemaPlayerTypeItems: Array<any>,
  cinemaChainCompanyItems: Array<any>,
}

export const DetailModal: React.FC<Props> = ({ childRef, locationTypeItems, cinemaPlayerTypeItems, cinemaChainCompanyItems }) => {
	useImperativeHandle(childRef, () => ({
    showModal(resourceEntityInfoId) {
      getDetail(resourceEntityInfoId)
      showModal()
      findEnumItemList()
    }
  }))

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const [detail, setDetail] = useState<any>()
  const getDetail = async(resourceEntityInfoId) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/resource/getEntityInfoById`,
      params: {
        resourceEntityInfoId,
      },
    })

    if (code === 1) {
      setDetail(data)
      setPreviewImg(data?.entityViewPath)
    }
  }

  // 院线
  const [cinemaChainIdItems, setCinemaChainIdItems] = useState<any>([])
  const findEnumItemList = async() => {
  	const key = ['CINEMA_CHAIN']
    const params = {
      groupKeyArray: key.join(','),
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })

    setCinemaChainIdItems(data['CINEMA_CHAIN'])
  }

  const [previewImg, setPreviewImg] = useState('')
  const showImg = (src) => {
    setPreviewImg(src)
  }

  return (
  	<Modal 
	    width={ 1140 }
	    title="影院详情"
	    visible={ isModalVisible }
	    footer={ null }
	    onCancel={ onModalCancel }
	    >
      <div className={ styles['modal-cont'] }>
        <div className={ styles['modal-cont-l'] }>
          <div className={ styles['entity-bigimg'] }><img src={ previewImg } /></div>
          <div className={ styles['entity-img-list'] }>
            <div className={ `${ styles['btn-left'] }`}><div className={ `imediafont i-arrow-right`}></div></div>
            <div className={ `${ styles['entity-img-item'] } ${ previewImg === detail?.entityViewPath ? styles['is-selected'] : ''}` } onClick={ () => { showImg(detail?.entityViewPath) } }><img src={ detail?.entityViewPath } /></div>
            <div className={ `${ styles['entity-img-item'] } ${ previewImg === detail?.entityViewPathOne ? styles['is-selected'] : ''}` } onClick={ () => { showImg(detail?.entityViewPathOne) } }><img src={ detail?.entityViewPathOne } /></div>
            <div className={ `${ styles['entity-img-item'] } ${ previewImg === detail?.entityViewPathTwo ? styles['is-selected'] : ''}` } onClick={ () => { showImg(detail?.entityViewPathTwo) } }><img src={ detail?.entityViewPathTwo } /></div>
            <div className={ `${ styles['btn-right'] }`}><div className={ `imediafont i-arrow-right`}></div></div>
          </div>
        </div>
        <div className={ styles['modal-cont-r'] }>
          <div className={ styles['entity-name'] }>{ detail?.entityName }</div>
          <div className={ styles['form'] }>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>专资编码：</div>
              <div className={ styles['form-item-value'] }>{ detail?.cinemaPropertyNumber }</div>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>是否为影管公司直营影院：</div>
              <div className={ styles['form-item-value'] }>{ detail?.directStoreEnabled ? '是' : '否' }</div>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>院线：</div>
              <div className={ styles['form-item-value'] }>{ convertItem(cinemaChainIdItems, detail?.cinemaChainId) }</div>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>开业时间：</div>
              <div className={ styles['form-item-value'] }>{ detail?.openDate }</div>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>位置类型：</div>
              <div className={ styles['form-item-value'] }>{ convertItem(locationTypeItems, detail?.locationType) }</div>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>营业时间：</div>
              <div className={ styles['form-item-value'] }>{ detail?.workStart }-{ detail?.workEnd }</div>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>影院管理系统（TMS系统）：</div>
              <div className={ styles['form-item-value'] }>{ convertItem(cinemaPlayerTypeItems, detail?.cinemaPlayerType) }</div>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-item-label'] }>位置：</div>
              <div className={ styles['form-item-value'] }>{ detail?.provinceName }{ detail?.cityName }{ detail?.districtName }{ detail?.addressMore }</div>
            </div>
          </div>

          <div className={ styles['entity-desc-tit'] }>场所简介</div>
          <div className={ styles['entity-desc'] }>{ detail?.description }</div>

          <div className={ styles['entity-desc-tit'] }>禁投行业：</div>
          <div className={ styles['entity-desc'] }>
            { detail?.antiIndustry && (
              <>
              {
                detail?.antiIndustryList.map(parent => {
                  return (
                    <div 
                      className={ styles['point-industry-item'] }
                      key={ parent.groupKey }
                      >
                      <div className={ styles['point-industry-label'] }>{ parent.groupValue }</div>
                      {
                        parent?.itemMap && (
                          <div className={ styles['point-industry-values'] }>
                            {
                              Object.entries(parent?.itemMap).map(([key, industry], index) => {
                                return (
                                  <div 
                                    className={ styles['point-industry-value'] }
                                    key={ index }
                                    >{ parent?.itemMap[key] }</div>
                                )
                              })
                            }
                          </div>
                        )
                      }
                      
                    </div>
                  )
                })
              }
            </>
            )}
            { !detail?.antiIndustry && (
              <>不限</>
            )}
          </div>
        </div>
      </div>
	  </Modal>
  )
}