import { Redirect } from 'react-router-dom'
import util from '../libs/util'

import { MainLayout } from '../layouts'
import { NotFound, ShareEntity } from '../pages'

import assistantRoute from './assistant'
import mineRoute from './mine'
import deliveryRoute from './delivery'
import entityRoute from './entity'
import pointRoute from './point'
import baseRoute from './base'

import contentRoute from './content'
import remoRoute from './remo'
import adExecRoute from './ad-exec'
import walletRoute from './wallet'

const isCinemaHost = window.location.host.includes('cinema.remudooh.com')
export const routes = [
  {
    path: '/',
    exact: true,
    render:() => {
      return <Redirect to={ isCinemaHost ? '/assistant/login' : '/sign/signIn' } />
    },
  },

  {
    path: '/share-entity/:shareId',
    component: ShareEntity,
  },

  // 热幕精灵 - 影院银幕广告助手
  ...assistantRoute,

  // 基础
  ...baseRoute,

  // 热幕商家
  {
    component: MainLayout,
    auth: true,
    routes: [
      ...mineRoute,
      ...deliveryRoute,
      ...entityRoute,
      ...pointRoute,
      ...adExecRoute,
      ...remoRoute,
      ...contentRoute,
      ...walletRoute,
      {
        path: '*',
        component: NotFound,
      },
    ]
  },

  {
    path: '*',
    component: NotFound,
  },
]