import React, { useState, useContext, useEffect } from 'react'
import { Select, Input, message, Checkbox, Radio } from 'antd'
import util from '../../libs/util'
import { appContext } from '../../App.state'
import styles from './ShareEntity.module.css'

const { Search } = Input
interface Props {
  match: {
    params: {
      shareId?: String,
    },
  },
}
const operatorTypeRealList = [ 
  {
    itemKey: 'CINEMA',
    itemValue: '银幕',
    text: '银幕',
  },
  {
    itemKey: 'LIGHTBOX',
    itemValue: '灯箱',
    text: '灯箱',
  },
  {
    itemKey: 'HALL_NAME',
    itemValue: '厅冠名',
    text: '厅冠名',
  },
  {
    itemKey: 'LCD',
    itemValue: '数字屏-LCD',
    text: '数字屏-LCD',
  },
  {
    itemKey: 'LED',
    itemValue: '数字屏-LED',
    text: '数字屏-LED',
  },
]

const defaultForm = {
  entityName: '',
  city: '',
  cinemaChainId: '',
  locationType: '',
}

export const ShareEntity: React.FC<Props> = (props) => {
  const appValue = useContext(appContext)
  const { shareId } = props.match.params

  useEffect(() => {
    getEntityList()
    getBaseInfo()
    getEnumItems()
  }, [])

  const [queryForm, setQueryForm] = useState(defaultForm)
  const [entityList, setEntityList] = useState<any[]>([])
  const [allList, setAllList] = useState<any[]>([])
  const [cityList, setCityList] = useState<any[]>([])

  useEffect(() => {
    getList()
  }, [queryForm])
  
  const getList = () => {
    const { entityName, city, cinemaChainId, locationType } = queryForm
    console.log('queryForm', { entityName, city, cinemaChainId, locationType })
    let result = true
    setEntityList(allList.filter(i => {
      result = true
      if (entityName) {
        result = i.entityName.toLocaleLowerCase().includes(entityName.toLocaleLowerCase())
      }
      if (city) {
        result = result && i.city === city
      }
      if (cinemaChainId) {
        result = result && i.cinemaChainId === cinemaChainId
      }
      if (locationType) {
        result = result && i.locationType === locationType
      }
      return result
    }))
  }

  const [operatorType, setOperatorType] = useState('')
  const getBaseInfo = async() => {
    const { data, code, total } = await util.request({
      method: 'GET',
      url: `/resource/public/getEntityShareBasic`,
      params: {
        shareId,
      },
    })
    if (code === 1) {
      setOperatorType(data.operatorType)
    }
  }

  const getEntityList = async() => {
    const { data, code, total } = await util.request({
      method: 'GET',
      url: `/resource/public/getEntityShare`,
      params: {
        shareId,
      },
    })
    if (code === 1) {
      data.forEach((item, index) => {
        getPointList(data, index)
      })
    }
  }
  const getPointList = async(list, entityIndex) => {
    let eList = [...list]
    const entityId = eList[entityIndex].resourceEntityInfoId
    const { data = [], code } = await util.request({
      method: 'GET',
      url: `/resource/public/getEntitySharePoints`,
      params: {
        shareId,
        entityId,
      },
    })
    if (code === 1) {
      eList[entityIndex].pointList = data
      setEntityList(eList)
      setAllList(JSON.parse(JSON.stringify(eList)))
      let cList:any = []
      eList.forEach(i => {
        if (!cList.find(j => j.itemKey === i.city)) {
          cList.push({
            itemKey: i.city,
            itemValue: i.cityName,
          })
        }
      })
      setCityList(cList)
    }
  }
  // 获取枚举值
  const [cinemaChainItems, setCinemaChainItems] = useState<any>([])
  const [locationTypeItems, setLocationTypeItems] = useState<any>([])
  const [cinemaScreenMaterialItems, setCinemaScreenMaterialItems] = useState<any>([])
  const [cinemaSoundTrackItems, setCinemaSoundTrackItems] = useState<any>([])
  const [lightboxMaterialItems, setLightboxMaterialItems] = useState<any>([])
  const getEnumItems = async () => {
    const { data, total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/getConfigList`,
      params: {
        groupKeyArray: ['CINEMA_CHAIN', 'LOCATION_TYPE', 'CINEMA_SOUND_TRACK', 'CINEMA_SCREEN_MATERIAL', 'LIGHTBOX_MATERIAL'].join(','),
      },
    })
    if (code === 1) {
      setCinemaChainItems(data['CINEMA_CHAIN'] || [])
      setLocationTypeItems(data['LOCATION_TYPE'] || [])

      setCinemaScreenMaterialItems(data['CINEMA_SCREEN_MATERIAL'] || [])
      setCinemaSoundTrackItems(data['CINEMA_SOUND_TRACK'] || [])

      setLightboxMaterialItems(data['LIGHTBOX_MATERIAL'] || [])
    }
  }

  const convertItem = (list, text) => {
    const item = list.find(i => i.itemKey === text)
    return item ? item.itemValue : ''
  }
  const convertItems = (list, value) => {
    return list.filter(i => value.includes(i.itemKey)).map(i => i.itemValue).join('，')
  }

  const onSearch = (entityName) => {
    setQueryForm((state) => {
      return {
        ...state,
        entityName,
      }
    })
  }

  const [filterType, setFilterType] = useState('')
  const [isPanelShow, setIsPanelShow] = useState(false)
  const toggleFilter = (type) => {
    setFilterType(type)
    if (!isPanelShow) {
      setTempData(queryForm[type])
    }
    setIsPanelShow(!isPanelShow)
  }

  const [tempData, setTempData] = useState('')
  const singleSelect = (value) => {
    setTempData(value)
  }

  const onClear = () => {
    setTempData('')
    setQueryForm((state) => {
      return {
        ...state,
        [filterType]: '',
      }
    })
    setIsPanelShow(false)
  }
  const onConfirm = () => {
    setQueryForm((state) => {
      return {
        ...state,
        [filterType]: tempData,
      }
    })
    setIsPanelShow(false)
  }

  return (
    <div className={ styles['share-entity-page'] } >
      <div className={ styles['entity-page-tit'] } >{ convertItem(operatorTypeRealList, operatorType) }资源</div>
      <div className={ styles['entity-search-wrap'] } >
        <div className={ styles['entity-search'] } >
          <Search
            allowClear 
            size='large'
            onSearch={ onSearch } 
            enterButton
            placeholder='请输入影院名称' />
        </div>
        <div className={ styles['entity-filter-wrap'] } >
          <div className={ styles['entity-filter'] } >
            <div className={ styles['entity-filter-item'] } onClick={ () => { toggleFilter('city') }}>
              {
                queryForm.city && (
                  <div className={ styles['txt-selected'] }>{ convertItem(cityList, queryForm.city) }</div>
                )
              }
              {
                !queryForm.city && '城市'
              }
              <div className={ `${ styles['entity-arrow'] } ${ (filterType === 'city' && isPanelShow) ? styles['up'] : '' } ${ queryForm.city ? styles['has-selected'] : '' }`} ></div>
            </div>
            <div className={ styles['entity-filter-item'] } onClick={ () => { toggleFilter('cinemaChainId') }}>
              {
                queryForm.cinemaChainId && (
                  <div className={ styles['txt-selected'] }>{ convertItem(cinemaChainItems, queryForm.cinemaChainId) }</div>
                )
              }
              {
                !queryForm.cinemaChainId && '院线'
              }
              <div className={ `${ styles['entity-arrow'] } ${ (filterType === 'cinemaChainId' && isPanelShow) ? styles['up'] : '' } ${ queryForm.cinemaChainId ? styles['has-selected'] : '' }`} ></div>
            </div>
            <div className={ styles['entity-filter-item'] } onClick={ () => { toggleFilter('locationType') }}>
              {
                queryForm.locationType && (
                  <div className={ styles['txt-selected'] }>{ convertItem(locationTypeItems, queryForm.locationType) }</div>
                )
              }
              {
                !queryForm.locationType && '位置类型'
              }
              <div className={ `${ styles['entity-arrow'] } ${ (filterType === 'locationType' && isPanelShow) ? styles['up'] : '' } ${ queryForm.locationType ? styles['has-selected'] : '' }`} ></div>
            </div>
          </div>
          {
            isPanelShow && (
              <>
                <div className={ styles['entity-filter-mask'] } ></div>
                <div className={ styles['entity-filter-panel'] } >
                  {
                    (filterType === 'city') && (
                      <div className={ styles['list'] } >
                        {
                          cityList.map((item, index) => {
                            return (
                              <div className={ `${ styles['item'] } ${ tempData === item.itemKey ? styles['is-selected'] : '' }` } key={ index } onClick={ () => { singleSelect(item.itemKey)} }>
                                { item.itemValue }
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }

                  {
                    (filterType === 'cinemaChainId') && (
                      <div className={ styles['list'] } >
                        {
                          cinemaChainItems.map((item, index) => {
                            return (
                              <div className={ `${ styles['item'] } ${ tempData === item.itemKey ? styles['is-selected'] : '' }` } key={ index } onClick={ () => { singleSelect(item.itemKey)} }>
                                { item.itemValue }
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }

                  {
                    (filterType === 'locationType') && (
                      <div className={ styles['list'] } >
                        {
                          locationTypeItems.map((item, index) => {
                            return (
                              <div className={ `${ styles['item'] } ${ tempData === item.itemKey ? styles['is-selected'] : '' }` } key={ index } onClick={ () => { singleSelect(item.itemKey)} }>
                                { item.itemValue }
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }

                  <div className={ styles['filter-opts'] } style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div className={ `${ styles['btn'] } ${ styles['btn-normal'] }` } onClick={ onClear }>清空</div>
                    <div className={ `${ styles['btn'] } ${ styles['btn-confirm'] }` } onClick={ onConfirm }>确定</div>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>

      {
        entityList.length > 0 && (<div className={ styles['entity-list'] } >
          {
            entityList.map((entity, entityIndex) => {
              return (
                <div className={ styles['entity-item'] } key={ entityIndex }>
                  <div className={ styles['entity-info'] }>
                    <div className={ styles['entity-image-cont'] }>
                      <img className={ `${ styles['entity-thumbnail'] }` } src={ `${ entity.entityViewPath }?imageMogr2/thumbnail/x56` } />
                      <div className={ styles['entity-chain'] }>{ convertItem(cinemaChainItems, entity.cinemaChainId) }</div>
                    </div>
                  <div className={ styles['entity-info-r'] }>
                    <div className={ styles['entity-name'] }>{ entity.entityName }</div>
                    <div className={ styles['entity-addr'] }>{ entity?.provinceName }{ entity?.cityName }{ entity?.districtName }{ entity?.addressMore }</div>
                    {
                      <div className={ styles['entity-labels'] }>
                        {
                          entity?.locationType && (<div className={ styles['entity-label'] }>{ convertItem(locationTypeItems, entity?.locationType) }</div>)
                        }
                        <div className={ styles['entity-label'] }>{ entity?.seatNumber }座</div>
                      </div>
                    }
                    <div className={ styles['entity-info-tips'] }>点位<span className={ styles['text-number'] }>{ entity.pointCount }</span>个</div>
                  </div>
                  </div>
                  <div className={ styles['point-list'] } >
                  {
                    (entity.pointList && entity.pointList.length > 0) && entity.pointList?.map((point, pointIndex) => {
                      return (
                        <div className={ styles['point-item'] }  key={ pointIndex }>
                          <div className={ styles['point-item-l'] }>
                            <img className={ `${ styles['point-thumbnail'] }` } src={ `${ appValue.rHost }${ point.environmentFilePath }?imageMogr2/thumbnail/x132` } />
                            <div className={ styles['point-number'] }>{ point.pointNumber }</div>
                          </div>
                          <div className={ styles['point-item-r'] }>
                            <div className={ styles['point-name'] }>{ point.pointName }</div>
                            <div className={ `${ styles['point-labels'] }` }>
                              {
                                point.seatNumber && (<div className={ `${ styles['point-label'] }` }>{ point.seatNumber }座</div>)
                              }
                              
                              {
                                (point.cinemaSoundTrack) && (<div className={ `${ styles['point-label'] }` }>{ convertItem(cinemaSoundTrackItems, point.cinemaSoundTrack) }</div>)
                              }
    
                              {
                                (point.cinemaScreenMaterial) && (<div className={ `${ styles['point-label'] }` }>{ convertItem(cinemaScreenMaterialItems, point.cinemaScreenMaterial) }</div>)
                              }

                              {
                                (point.lightboxLocationInfo) && (<div className={ `${ styles['point-label'] }` }>{ point.lightboxLocationInfo }</div>)
                              }

                              {
                                (point.ledLocationInfo) && (<div className={ `${ styles['point-label'] }` }>{ point.ledLocationInfo }</div>)
                              }

                              {
                                (point.lcdLocationInfo) && (<div className={ `${ styles['point-label'] }` }>{ point.lcdLocationInfo }</div>)
                              }

                              {
                                point.lightboxFrameWidth && (<div className={ `${ styles['point-label'] }` }>{ point.lightboxFrameWidth } * { point.lightboxFrameHeight }cm</div>)
                              }

                              {
                                (point.lightboxMaterial && point.lightboxMaterial.length > 0) && (<div className={ `${ styles['point-label'] }` }>{ convertItems(lightboxMaterialItems, point.lightboxMaterial) }</div>)
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                  </div>
                </div>
              )
            })
          }
        </div>)
      }

      {
        entityList.length <= 0 && (<div className={ styles['no-record'] } >无数据~</div>)
      }
    </div>
  )
}